.container {
    min-height: 100vh;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loading {
    font-size: 18px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); // Три элемента в ряду
    gap: 15px;
    width: 100%;
    max-width: 500px;
    justify-content: center;
}

.card {
    width: 65px;
    height: 85px;
    background: linear-gradient(145deg, #21996c, #007841); // Градиентный фон
    border-radius: 30px; // Закругленные углы
    border: 3px solid #baeae7; // Внешняя обводка
    box-shadow: inset 0 -5px 0 #01472d;

    padding: 0px 20px 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition:
        transform 0.2s,
        box-shadow 0.3s;

    &:active {
        transform: scale(1.05);
        box-shadow: inset 0 2px 0 #2d2d2d;
    }

    .day {
        font-size: 21px;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 8px;
        color: #01472d;
    }

    .reward {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0px;

        p {
            font-size: 19px;
            color: #baeae7;
            margin: 0;
        }
    }

    img {
        width: 40px;
        height: auto;
    }
}

.card:last-child {
    grid-column: 2; /* Помещает последний элемент во второй (средний) столбец */
    justify-self: left; /* Центрирует элемент в сетке */
}

@media (max-width: 380px) {
    .card {
        width: 60px;
        height: 60px;
        padding: 20px;
    }
}

.card.active {
    background: linear-gradient(145deg, #f69e2a, #e67a1c); // Градиентный фон
    border: 3px solid #ffe59f; // Внешняя обводка

    .day {
        text-transform: uppercase;
        font-weight: bold;
        color: #904603;
    }
}

// .card.claimed {
//     .rewardAmount {
//         // font-size: 17px;
//         margin: 0px;
//     }

//     // img {
//     //     width: 50px;
//     //     height: auto;
//     // }
// }

.rewardIcon {
    width: 50px;
    height: 50px;
}

.rewardAmount {
    font-size: 17px;
    margin: 5px;
}
