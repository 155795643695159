.actionButtons {
    position: absolute;
    top: 100%;
    right: 0%;

    button {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 2px solid #fff396;
        background: linear-gradient(to right, #efc437, #f59809);
        box-shadow: inset 0 -3px 0 #613405;
        cursor: pointer;

        display: flex; /* Центрируем содержимое кнопки */
        align-items: center;
        justify-content: center;

        transition:
            transform 0.3s,
            box-shadow 0.3s;
    }

    button:hover {
        transform: translateY(-3px);
    }

    button:active {
        transform: translateY(3px);
        box-shadow: inset 0 2px 0 #8a4b00;
    }

    button img {
        width: 15px;
        height: 15px;
    }
}
