.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}

.modal.open {
  opacity: 1;
  visibility: visible;
  transform: scale(1.1);
  background-color: #0000008c;
}

.modal-content {
  position: relative;
  /* padding: 20px;
  min-width: 70%;
  max-width: 70%; */
  padding: 10px 10px 10px 10px;
  min-width: 70%;
  max-width: 80%;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  width: 100%;
  list-style: none;
  background: url("../../assets/modals/only-bg-modal.png") no-repeat center;
  background-size: contain;

  color: var(--secondary);
  text-align: center;
}

/* .modal-content-energy {
  position: relative;
  padding: 20px;
  min-width: 70%;
  max-width: 70%;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  width: 100%;
  list-style: none;
  background: url("../../assets/modals/only-bg-modal.png") no-repeat center;
  background-size: contain;

  color: var(--secondary);
  text-align: center;
}

.modal-content-coins {
  position: relative;
  padding: 20px;
  min-width: 70%;
  max-width: 70%;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  width: 100%;
  list-style: none;
  background: url("../../assets/modals/only-bg-modal.png") no-repeat center;
  background-size: contain;

  color: var(--secondary);
  text-align: center;
} */

.modal-content-welcome {
  position: relative;
  padding: 20px;
  min-width: 70%;
  max-width: 70%;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  width: 100%;
  list-style: none;
  background: url("../../assets/modals/Welcome.png") no-repeat center;
  background-size: contain;

  color: var(--secondary);
  text-align: center;
}

.modal.open .modal-content {
  transform: scale(1);
}

.modal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 150, 255, 0.2),
    transparent
  );
  background-size: 400% 400%;
  animation: glowing 5s ease-in-out infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.open::before {
  opacity: 1;
}

@keyframes glowing {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.close {
  background: url("../../assets/modals/Cancel.png") no-repeat center;
  background-size: contain;
  color: var(--secondary);
  position: absolute;
  right: -5px;
  top: 50px;

  width: 50px;
  height: 50px;
  z-index: 1;
}

h2 {
  text-align: center;
  color: #fff;
}

.accept {
  background: url("../../assets/modals/Ok.png") no-repeat center;
  background-size: contain;
  position: absolute;
  left: 20px;
  width: 70px;
  height: 70px;
  top: 180px;
}

.continue {
  background: url("../../assets/modals/Continue.png") no-repeat center;

  background-size: contain;
  left: 20px;
  width: 150px;
  height: 50px;
  color: #613405;
  text-transform: uppercase;
}

/* .modal-content-hero {
  background: url("../../assets/modals/Welcome.png") no-repeat center;
  background-size: contain;

  width: 100%;
  height: 200px;
} */
