.hypeContainer {
    margin-top: -25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px;
    color: white;
    // min-height: 100vh;

    overflow-y: scroll;
    height: 550px;

    span {
        color: #83ffd1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;

        h3 {
            margin: 0;
        }
    }
}

.header {
    background: linear-gradient(to bottom, #a93edc, #8b13c9);
    border: solid 4px #ff8fff;
    border-radius: 25px;

    padding: 0px 10px;
    text-align: center;
    font-weight: bold;

    p {
        font-size: 22px;
    }
}

.coinsIcon {
    margin-left: 5px;
    width: 30px;
    height: 30px;
}

.coinsIconTable {
    margin-left: 5px;
    width: 20px;
    height: 20px;
}

.platforms {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    gap: 10px;
}

.platformIcon {
    width: 40px;
    height: 40px;
}

.instructions {
    background: linear-gradient(to bottom, #a93edc, #8b13c9);
    border: solid 4px #ff8fff;
    border-radius: 25px;

    padding: 5px;
    text-align: center;
    margin-bottom: 10px;
    font-size: 16px;
}

.rewards {
    background: linear-gradient(to bottom, #a93edc, #8b13c9);
    border: solid 4px #ff8fff;
    border-radius: 25px;
    padding: 5px;
    text-align: center;
    width: 300px;
}

.rewardTable {
    width: 100%;
}

.rewardTable th,
.rewardTable td {
    color: white;
}

.rewardTable th {
    font-size: 22px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

.rewardTable td {
    font-size: 16px;
    border-radius: 5px;
    font-weight: bold;
}

//BUTTON

.addLinkButton {
    display: inline-block;
    width: 170px;
    height: 40px;
    color: #552c0b;
    background: linear-gradient(to right, #efc437, #f59809);
    border-radius: 50px;
    border: 2px solid #fff396;
    cursor: pointer;
    transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
    position: relative;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;

    box-shadow: inset 0 -3px 0 #613405;
}

.addLinkButton:hover {
    transform: translateY(-3px);
}

.addLinkButton:active {
    transform: translateY(3px);
    box-shadow: inset 0 2px 0 #8a4b00;
}

// Адаптивность для экранов шириной меньше 400px

@media (max-width: 420px) {
    .hypeContainer {
        margin-top: 0; /* Убираем отрицательные отступы */
        padding: 5px 10px; /* Уменьшаем padding */

        overflow-y: scroll;
        height: 400px;
    }

    .header {
        font-size: 16px; /* Уменьшаем шрифт заголовка */
        padding: 5px; /* Уменьшаем padding */
        p {
            font-size: 16px; /* Уменьшаем шрифт заголовка */
        }
    }

    .coinsIcon {
        width: 20px;
        height: 20px; /* Уменьшаем размер иконки */
    }

    .platforms {
        gap: 5px; /* Уменьшаем gap между платформами */
    }

    .platformIcon {
        width: 30px;
        height: 30px; /* Уменьшаем размер иконок платформ */
    }

    .instructions {
        font-size: 14px; /* Уменьшаем размер текста инструкций */
        padding: 5px; /* Уменьшаем padding */
    }

    .rewards {
        width: 250px;
        height: 100%;
        padding: 5px;
        overflow-y: scroll;
        max-height: 90px;
    }

    .rewardTable th {
        font-size: 18px; /* Уменьшаем размер заголовков таблицы */
    }

    .rewardTable td {
        font-size: 14px; /* Уменьшаем размер текста в таблице */
    }

    .addLinkButton {
        width: 140px; /* Уменьшаем ширину кнопки */
        height: 35px; /* Уменьшаем высоту кнопки */
        font-size: 18px; /* Уменьшаем шрифт кнопки */
    }
}
