.background {
  top: 0;
  bottom: 0;
  max-width: 100%;
  min-width: 100%;
  min-height: 100vh;
  object-fit: cover;
  z-index: -1;
  &.noscroll {
    .background-img {
      height: 100%;
    }
  }
  .background-img {
    position: absolute;
    object-fit: cover;
    width: 100%;

    z-index: -1;
  }
}

.content {
  position: absolute;
  bottom: 200px;
  top: 320px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.farm-content {
  position: absolute;
  bottom: 250px;
  top: 220px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.wallet-content {
  position: absolute;
  bottom: 250px;
  top: 220px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.tasks-content {
  position: absolute;
  bottom: 250px;
  top: 220px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.wheel-content {
  top: 150px;
  position: absolute;
  display: flex;
  // height: 100vh;
  z-index: 1;
  width: -webkit-fill-available;
  width: -moz-available;
}

// @media (max-width: 380px) {
//   .wallet-content {
//     position: absolute;
//     bottom: 250px;
//     top: 130px;
//     width: -webkit-fill-available;
//     width: -moz-available;
//   }

//   .tasks-content {
//     position: absolute;
//     bottom: 250px;
//     top: 130px;
//     width: -webkit-fill-available;
//     width: -moz-available;
//   }
// }
@media (max-width: 420px) {
  .content {
    top: 250px;
  }

  .farm-content {
    position: absolute;
    bottom: 250px;
    top: 120px;
    width: -webkit-fill-available;
    width: -moz-available;
  }
}

@media (max-height: 720px) {
  .wallet-content {
    position: absolute;
    bottom: 250px;
    top: 130px;
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .tasks-content {
    position: absolute;
    bottom: 250px;
    top: 130px;
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .noscroll {
    .content {
      top: 110px;
    }
  }
}

.go-to-planets-button {
  background: url("../../assets/modals/Back.png") no-repeat center;

  background-size: contain;
  width: 125px;
  height: 50px;
  color: #613405;
  text-transform: uppercase;
  position: fixed;
  bottom: 14%;
  left: 5%;
}
