.planet-selector-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  position: relative;
}

.slider-arrows {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
  padding: 0 20px;
}

.custom-button {
  background: none;
  border: none;
  padding: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #ccc0;
}

.slider-arrow {
  width: 130%;
  height: auto;
}

.planet {
  position: absolute;
  cursor: pointer;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.main-planet {
  z-index: 2;
  transform: translateX(0) scale(1);
  opacity: 1;
}

.left-planet {
  margin-top: 100px;
  z-index: 1;
  transform: translateX(-85%) scale(0.5);
  opacity: 0.5;
}

.right-planet {
  margin-top: 100px;
  z-index: 1;
  transform: translateX(85%) scale(0.5);
  opacity: 0.5;
}

.hidden-planet {
  z-index: 0;
  transform: translateX(200%) scale(0.5);
  opacity: 0;
  display: none;
}

.planet-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.planet-img-content {
  width: 250px;
  height: 250px;
}

.small-planet .planet-img-content {
  width: 80px;
  height: 80px;
}

.main-planet .planet-img-content {
  width: 300px;
  height: 300px;
}

.farm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (max-width: 420px), (max-height: 720px) {
  .planet-selector-container {
    max-height: 380px;
    margin-top: 30px;
  }

  .slider-arrows {
    margin-top: 0px;
  }
}

.earth-button {
  position: absolute;
  left: 20px;
  top: -60px;
  border-radius: 0;
  z-index: 9;
}

.games-container {
  position: absolute;
  right: 0;
  top: -20%;
}

.comet-img {
  width: 60px;
  height: 60px;
}

@media (max-width: 420px), (max-height: 720px) {
  .main-planet .planet-img-content {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 420px) {
  .earth-button {
    height: 130px;
    width: 105px;
    left: 6px;
    top: 25px;
  }
}

@media (max-width: 360px) {
  .earth-button {
    left: 10px;
    top: 35px;
  }
}

@media (max-height: 720px) {
  .earth-button {
    left: 10px;
    top: -70px;
  }
}
