.energy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.energy-header {
  margin-bottom: 10px;
}

.energy-card {
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  width: 90%;
  max-width: 350px;
}

.energy-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.energy-max {
  font-weight: bold;
  color: #ff5722;
}

.energy-task {
  display: flex;
  align-items: center;
  gap: 5px;
}

.energy-invite,
.invite-left {
  color: #333;
}

.energy-status {
  background: url("../../assets/modals/energy-button.png") no-repeat center;
  background-size: contain;
  width: 200px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
}

.img-in-text {
  width: 20px;
  height: auto;
}

.modal-energy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 250px;
}

.energy-button {
  background: url("../../assets/modals/get-energy-button.png") no-repeat center;

  background-size: contain;
  left: 20px;
  width: 150px;
  height: 50px;
  color: #c3f2f6;
  text-transform: uppercase;
}

.numbers-energy {
  color: #613405;
}

.energy-modal-img {
  width: 65px;
  height: 65px;
  position: absolute;
  top: 11%;
  left: 1%;
}
