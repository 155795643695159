.balances {
  background-image: url("../../../assets/header/balance.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding: 10px 10px 10px 10px;
  width: 100%;
  // height: 50px;

  p {
    color: #ffffff;
    // text-shadow: 0 0 5px #c971ff;
    font-size: 32px;
    padding-top: 1%;
  }
}

@media (max-width: 420px) {
  .balances {
    padding: 10px 20px 10px 10px;
  }
}

@media (max-height: 720px) {
  .balances {
    padding: 10px 29px 10px 10px;
  }
}
