.cometOnboarding {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1002;
  background-color: #0000008c;

  p {
    color: #fff;
  }
  .cometContainer {
    .cometImage {
      position: absolute;
      top: 10%;
      left: 10%;
      width: 100px;
      cursor: pointer;
    }

    .fingerImage {
      position: absolute;
      top: 20%;
      left: 22%;
      width: 80px;
      animation: pulse 1.5s infinite;
    }
  }

  .secondCat {
    height: 350px;
    position: absolute;
    bottom: 120px;
    z-index: 1;
  }

  .firstCat {
    height: 400px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 3;
  }

  .rewardsImage {
    position: absolute;
    margin-left: 15px;
    width: 95%;
    max-width: 450px;
  }

  .rect {
    position: absolute;
    display: flex;
    align-items: center;
    height: 150px;
    width: 100%;
    border: 4px solid #ffcaff;
    border-radius: 25px 25px 0 0;
    background-color: #812fa8;
    bottom: 0;
    left: 0;
    z-index: 2;
    .prompttext {
      padding: 10px;
      margin-right: 40%;
      font-size: 22px;
    }
    .rewardtext {
      padding: 10px;
      font-size: 22px;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
