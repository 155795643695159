
  .modalSpins {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
  }
  
  .spinButton {
    background: url("../../assets/modals/get-energy-button.png") no-repeat center;
    background-size: contain;
    width: 150px;
    height: 50px;
    color: #c3f2f6;
    text-transform: uppercase;
  }
  
