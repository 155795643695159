.exchangeContent {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 15%;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  
  .formGroup {
    margin-bottom: 15px;
    display: flex;
  }
  
  .label {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .input {
    width: 100%;
    padding: 8px;
    border: 2px solid #fecafe;
    border-radius: 8px;
    background: transparent;
    max-width: 200px;
    margin-top: 6px;
    color: #fff;
  
    &::placeholder {
      color: #fecafe; /* Your desired color */
      opacity: 1;
    }
  
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
      color: #fecafe;
    }
  
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #fecafe;
    }
  }
  
  
  .warning {
    margin-top: 5px;
    color: #d9534f;
    font-size: 14px;
  }
  
  .submitButton {
    padding: 10px 15px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    background: url("../../assets/modals/get-energy-button.png") no-repeat center;

    background-size: contain;
    left: 20px;
    width: 150px;
    height: 50px;
    color: #c3f2f6;
    text-transform: uppercase;
  
    &:hover {
      background-color: transparent;
    }

    &:disabled {
      background-color: transparent;
    }
  }
  
  .coinImg {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 0%;
    left: 0%;
  }
  