.preloader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8); // Затемненный фон
  z-index: 9999;

  .spinner {
    width: 80px;
    height: 80px;
    background-image: url("../../../assets/big-earth.png"); // Путь к картинке
    background-size: cover;
    animation: spin 1s linear infinite;
  }

  .progress-bar-container {
    width: 80%;
    max-width: 400px;
    background-color: #ccc;
    height: 20px;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 20px;

    .progress-bar {
      height: 100%;
      background-color: #3cbd2d;
      transition: width 0.3s ease-in-out;
    }
  }

  .progress-text {
    margin-top: 10px;
    font-size: 16px;
    color: #fff;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
