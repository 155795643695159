.comet {
  position: absolute;
  width: 70px;
  height: 70px;
  cursor: pointer;
  pointer-events: auto;
}

.cometContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 999;
}

.reward {
  position: absolute;
  background-color: transparent;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 21px;
  pointer-events: none;
  z-index: 1000;
  transform: translate(-50%, -50%);
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
