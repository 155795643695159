.bottomPanel {
  position: fixed;
  bottom: 20px;
  height: 100%;
  max-height: 12vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1001;
}

.backgroundOverlay {
  position: fixed; /* Фиксированное положение */
  bottom: 0; /* Прижимаем к нижней части экрана */
  left: 0;
  width: 100%; /* Занимает всю ширину */
  height: 30px; /* Задаем высоту подложки */
  background-color: #8e17cc; /* Задаем цвет */
  z-index: 0; /* Устанавливаем нижний z-index, чтобы она была под другими элементами */
}

@media (max-width: 385px), (max-height: 720px) {
  .bottomPanel {
    bottom: 30px;
  }
}
