.content-page {
  position: relative;
  height: 90%;
  width: 95%;
  padding: 5% 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.shop-container {
  position: relative;
  height: 65vh;
  top: -90px;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.content-page-buy {
  position: relative;
  width: 100%;
  padding: 5% 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-item-buy {
  min-height: 50px;
  min-width: 40%;
  padding: 5%;
  list-style: none;
  background-color: #555555d5;
  border-radius: 15px;
  color: var(--secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-item {
  position: relative;
  height: 100px;
  width: 95%;
  min-height: 75px;

  list-style: none;
  background-image: url("../../assets/shop/Buy-frame.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.planet-content-item {
  height: 100px;
  width: 100%;
  list-style: none;
  background-image: url("../../assets/shop/Shop-earh.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.planet-content-item-moon {
  height: 100px;
  width: 100%;
  list-style: none;
  background-image: url("../../assets/shop/Shop-moon.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

#content-item-texts {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
  font-family: "RaceSport";
}

.content-item-texts-name {
  font-size: 1.2em;
  color: #fff;
}

.content-item-texts-info {
  display: flex;
  gap: 5px;
  color: #fff;
}

.content-item-texts-info span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.content-item-img {
  width: 50px;
  height: 50px;
}

.ico-in-text {
  height: 1em;
  width: 1em;
}

.quantity-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
}

.shop-button {
  border: none;
  background-image: url("../../assets/shop/Buy.png");
  background-size: contain;

  height: 50px;
  width: 75px;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-right: -10px;
}

.shop-button:disabled {
  border: none;
  background-image: url("../../assets/shop/Unlock.png");
  background-size: contain;
  background-color: transparent;

  height: 50px;
  width: 75px;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-right: -10px;
}

.content-item-button-buy {
  width: 50%;
  border-radius: 10px;
}

.modal-shop {
  background-image: url("../../assets/shop/Information.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-shop {
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
}

@media (max-width: 420px), (max-height: 720px) {
  .content-page {
    position: relative;
    height: 100%;
    width: 100%;
    gap: 1%;
    padding: 5% 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }

  .shop-container {
    position: relative;
    height: 65vh;
    top: 0px;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }

  .content-item {
    position: relative;
    height: 70px;
    width: 90%;
    min-height: 80px;
    list-style: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0;
  }
}

.upgradeBut {
  position: absolute;
  left: -5px;
  top: -5px;
  height: 40px;
  width: auto;
  cursor: pointer;
}
