.header {
  position: fixed;
  left: 0;
  top: 11vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  background: var(--background-color);
  z-index: 1001;
  width: 100%;
  max-width: 100vw;

  p {
    font-family: "RaceSport";
  }
}

@media (max-width: 385px), (max-height: 720px) {
  .header {
    top: 2vh;
  }
}

.topHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // height: 70px;
  color: var(--secondary);
  width: 100%;
  font-size: 20px;
}

.leftHeader,
.rightHeader {
  display: flex;
  align-items: center;
  background-size: contain;
}

.leftHeader {
  // background-image: url("../../assets/header/money.png");
  flex-direction: column;
  align-items: flex-start;
  width: 280px;
  height: 50px;
  .hypeButton {
    height: 60px;
    margin: 5px;
    cursor: pointer;
  }
  .wheelButton {
    height: 60px;
    margin: 5px;
    cursor: pointer;
  }
  .fortuneContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 5px;
  }
}

.rightHeader {
  flex-direction: column;
  align-items: flex-end;
  width: 150px;
  justify-content: space-evenly;
  z-index: 101;
}

.energyContainer {
  font-size: 21px;
  background-size: cover;
  width: 80%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/header/energy.png");
  p {
    color: #613405;
    margin-left: 35%;
    padding-top: 5%;
  }
}

// .leftHeader p,
// .rightHeader p {
//   // font-family: "RaceSport";
//   // color: var(--secondary);
//   // color: "#613405";
//   // margin-left: "35%";
//   // padding-top: "2%";
// }
