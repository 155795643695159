.tasksContainer {
  margin-top: -5px;
  position: relative;
}

.topButtons {
  display: flex;
  justify-content: center;

  button {
    cursor: pointer;
    position: relative;
    margin: 0;
    width: 100%;
    border-radius: 20px 20px 10px 10px;
    height: 50px;
    font-size: 24px;
    background: linear-gradient(to top, #8323b1, #b649eb);
    border: solid 3px #e3a9ff;
    color: #e3a9ff;
  }

  button.active {
    background: linear-gradient(to top, #df6d17, #f8a32b);
    border: solid 3px #ffde83;
    color: #ffde83;
  }

  // button:hover {
  //   transform: translateY(-3px);
  // }

  // button:active {
  //   transform: translateY(3px);
  //   box-shadow: inset 0 2px 0 #8a4b00;
  // }
}

.earn-content {
  padding: 20px;
  overflow-y: scroll;
  max-height: 55vh;
  margin-top: 5px;

  .tasks-container {
    padding-left: 20px;
  }

  .task {
    background: linear-gradient(to right, #0a7164, #33d199);
    border-radius: 20px;
    border: 3.5px solid #a8dfff;
    position: relative;
    height: 70px;
    max-width: 360px;
    width: 80vw;
    color: #fff;
    margin: 0 auto;
    margin-top: 25px;
    &.youtube {
      background: linear-gradient(to right, #d51f13, #fb463b);
      border-color: #ffc6c6;
    }
    &.friends {
      background: linear-gradient(to right, #017033, #13cc67);
      border-color: #aefac0;
    }
    &.telegram {
      background: linear-gradient(to right, #095ba3, #319fff);
      border-color: #a8e9f9;
    }
    .text {
      margin-left: 55px;
      padding-right: 60px;
      text-transform: uppercase;
      margin-top: 5px;
      line-height: 1;
    }
    .logo {
      z-index: 1;
      position: absolute;
      transform: rotate(15deg);
      width: 33px;
      left: -6px;
      bottom: 23px;
    }

    .logoPartner {
      z-index: 1;
      position: absolute;
      transform: rotate(15deg);
      width: 60px;
      left: -20px;
      bottom: 10px;

      border-radius: 20px;

      // &:first-of-type {
      //   width: 100px;
      //   left: -40px;
      //   bottom: -15px;
      // }
    }

    .crystal {
      z-index: 1;
      position: absolute;
      width: 95px;
      top: -20px;
      left: -35px;
    }
    .crystalYT {
      z-index: 1;
      position: absolute;
      width: 78px;
      top: -14px;
      left: -25px;
    }
    .reward {
      display: flex;
      align-items: flex-end;
      gap: 10px;
      flex-direction: row;
      font-family: "RaceSport";
      position: absolute;
      left: -3.5px;
      width: 200px;
      bottom: -3.5px;
      padding: 2px;
      border-radius: 20px;
      border: 3.5px solid #aefac0;
      background: linear-gradient(to right, #21996c, #21996c);
      .tokens {
        display: flex;
        flex-direction: row;
        gap: 3px;
        margin-left: 45px;
        align-items: flex-end;
      }
      .plus {
        width: 15px;
        align-self: center;
      }
      .coin {
        width: 25px;
      }
      .energy {
        width: 25px;
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        gap: 3px;
      }
    }
    .plusbutton {
      position: absolute;
      width: 70px;
      right: 5px;
      bottom: 2px;
      cursor: pointer;
    }
    .disabled {
      pointer-events: none;
    }
  }
}

@media (max-width: 420px), (max-height: 720px) {
  .earn-content {
    padding: 20px;
    overflow-y: scroll;
    max-height: 55vh;
    margin-top: 0px;
  }
}
