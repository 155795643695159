.levelContainer {
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  /* width: 100%; */
  width: 41vw;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    margin-left: 17%;
    padding-top: 5%;
    font-size: 24px;
  }
}

@media (max-width: 420px) {
  .levelContainer {
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    height: 52px;
    justify-content: flex-start;
    width: 41vw;
  }
}
