.catComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  margin-top: -100px;
  max-height: 575px;

  .topButtons {
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      cursor: pointer;
      position: relative;
      margin: 0;
      width: 100%;
      border-radius: 20px 20px 10px 10px;
      height: 50px;
      font-size: 24px;
      background: linear-gradient(to top, #8323b1, #b649eb);
      border: solid 3px #e3a9ff;
      color: #e3a9ff;
    }

    button.active {
      background: linear-gradient(to top, #df6d17, #f8a32b);
      border: solid 3px #ffde83;
      color: #ffde83;
    }

    // button:hover {
    //   transform: translateY(-3px);
    // }

    // button:active {
    //   transform: translateY(3px);
    //   box-shadow: inset 0 2px 0 #8a4b00;
    // }
  }
}

.catRing {
  background: url("../../assets/leaderboard/circle/1-circle.png") no-repeat center center;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.circleImage {
  position: absolute;
  width: 100%;
}

.catImage {
  position: absolute;
  width: 50%;
}

.titleImage {
  position: absolute;
  width: 50%;
  margin-top: 25%;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 420px), (max-height: 720px) {
  .catComponent {
    margin-top: -40px;
    height: 520px;
  }
  .circleImage {
    position: absolute;
    width: 80%;
  }
  .catImage {
    position: absolute;
    width: 30%;
  }
  .titleImage {
    position: absolute;
    width: 50%;
    margin-top: 30%;
  }
}

@media (max-width: 380px), (max-height: 720px) {
  .catComponent {
    margin-top: 0px;
    height: 450px;
  }
}
