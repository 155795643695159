.modal-closed-planet {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
}

.container-closed-planet {
  height: 100px;
  width: 100%;
  list-style: none;

  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--secondary);
}

.task-closed-planet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-transform: uppercase;
  margin-top: 10px;
}
.invite-img {
  width: 50px;
  height: 50px;
}

.crystall-container {
  display: flex;
  flex-direction: row;
}

.locked-planet-img {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0%;
  left: 0%;
}
