/* PlantingBuildContent.css */
.modal-heading {
  list-style: none;
  background: url("../../assets/modals/Name.png") no-repeat center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  position: relative;
  top: -15px;
}

.build-desc {
  text-align: center;
  color: #fff;
  padding: 0px 10px 10px 75px;
  font-size: 14px;
}

.token-image-build {
  width: 25px;
  height: 25px;
  z-index: 1;
}

.price-info {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.build {
  background: url("../../assets/modals/build-cost.png") no-repeat center;

  font-family: "RaceSport";
  width: 90%;
  border-radius: 10px;
  background-size: contain;
  color: black;
  font-weight: 600;
  height: 90px;
  padding: 10px 0;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;

  margin-top: -50px;
}

.build-text {
  font-size: 16px;
  margin: 5px;
}

.build-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
