.img-in-text {
  width: 20px;
  height: 20px;
}

.task-item-container-invite {
  height: 100px;
  width: 100%;
  list-style: none;

  background: url("../../assets/tasks/invite.png") no-repeat center;
  background-size: contain;

  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--secondary);

  height: 65px;
}

.modal-coins {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
}

.task-invite {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-transform: uppercase;
  margin-top: 10px;
  gap: 1px;
  font-size: 15px;
}

.task-item-container-join {
  height: 100px;
  width: 100%;
  list-style: none;

  background: url("../../assets/modals/join-chanel.png") no-repeat center;
  background-size: contain;

  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--secondary);

  height: 65px;
}

.coin-img {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0%;
  left: 0%;
}
